// REACT AND GATSBY IMPORTS
import React, { useState, useEffect } from 'react';
// CONTAINERS AND COMPONENTS
import Box from 'reusecore/src/elements/Box';
import Button from 'reusecore/src/elements/Button';
// import Image from 'reusecore/src/elements/Image';
import Container from 'common/src/components/UI/Container';
import PodcastTile from 'common/src/components/InfoTile/podcastTile';
import { AllJobs } from './AllJobs'
// STYLING
import JobListWrapper, { Styles } from './jobList.styles'; // CSS styling
import { InputHandler } from './handlers'; // Code to handle user I/O
// NPM MODULES
// LOGIC

// List of user jobs
const JobList = ({ heading, redirectPath, tileClickCB }) => {
  const [user, updateUser] = useState({});
  const { row, podCol } = Styles;

  // Performs initial fetch of user's jobs on component load
  useEffect(() => {
    async function fetchData() {
      updateUser(await InputHandler.getUser());
    }
    fetchData();
  }, []);

  return (
    <JobListWrapper>
      <Container className="user_list_container">

        {/*** TITLE BAR (optional) ***/}
        {heading ?
        <Box {...row}>
          <h1>{heading}</h1>
        </Box>:<></>}

        <Box {...row}>
          <Button
            title="START NEW TRANSCRIPT"
            onClick={async () =>
              InputHandler.redirectToPage('users/newTranscript')
            }
          />
        </Box>

        {/** ALL USER JOBS */}
        <AllJobs heading="All Transcription Jobs" redirectPath='users/job'/>
      </Container>
    </JobListWrapper>
  );
};

export default JobList;
