// REACT AND GATSBY
import React from 'react';
// import { Redirect } from '@reach/router'
// ELEMENTS AND COMPONENTS
import InfoTile from 'common/src/components/InfoTile';
// LOGIC AND HANDLERS
import { redirectToPage } from '../../../../api/BrowserAPI';
// NPM MODULES

// A Podcast wrapper around a generic Infotile
const PodcastTile = ({ tileStyle, podcast, redirectObj }) => {
  // const IndexPage = () => <Redirect to={`/en`} /> // highlight-line

  // console.log({tileStyle, podcast, redirectObj})
  return (
  <>
    <InfoTile
      data={{
        title: podcast.title || podcast.title_original,
        image: podcast.image,
        description: (podcast.description || podcast.description_original),
        // subtitle:
        meta1: podcast.publisher_original,
        meta2: podcast.total_episodes + ' episodes',
      }}
      tileStyle={tileStyle}
      onImageClick={() => {
        if (redirectObj){
          // console.log(redirectObj)
          const { path, queryParams, onImageClickCB } = redirectObj;
          if (onImageClickCB){
            onImageClickCB(podcast)
          } else if (path && queryParams){
            redirectToPage(path, queryParams)
          }
        }
      }}
      onTitleClick={() => {
        if (redirectObj){
          // console.log(redirectObj)
          const { path, queryParams, onTitleClickCB } = redirectObj;
          if (onTitleClickCB){
            onTitleClickCB(podcast)
          } else if (path && queryParams){
            redirectToPage(path, queryParams)
          }
        }
      }}
      />
  </>
  );
};

export default PodcastTile;
