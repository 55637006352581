/**
 * Handler for input events for UserList
 *
 * This is where we call our custom API to manage JobList requests
 */

import { doFetch as podstacksAPI } from '../../../../api/PodstacksAPI';
// import { redirectToPage, getUrlParams } from '../../../../api/BrowserAPI';
import { getUserId } from '../../../../api/UserLoginAPI'

// Fetches a user from database
// const getUser = async () => {
//   //const { userId } = getUrlParams();
//   const userId = getUserId()
//   console.log('Getting user: ' + userId);
//   const user = await podstacksAPI(
//     'users',
//     'getUser',
//     { userId },
//     null,
//     null,
//     null,
//     null
//   );
//   return user;
// };


// Fetches Jobs from a specific user
export const getJobs = async () => {
  const userId = getUserId()
  console.log('Getting jobs from user: ' + userId);
  return podstacksAPI(
    'jobs',
    'getUserJobs',
    { userId },
  );
};


// export const InputHandler = {
//   getUser,
//   getJobs,
//   redirectToPage,
// };
