// REACT AND GATSBY
import React, { Fragment } from 'react';
// import { Redirect } from '@reach/router'

// ELEMENTS AND COMPONENTS
import InfoTile from 'common/src/components/InfoTile';

// LOGIC AND HANDLERS
// import { InputHandler } from './handlers';
import { redirectToPage } from '../../../../api/BrowserAPI';

// NPM MODULES
import moment from 'moment';
import pms from 'pretty-ms';

// An episode wrapper around a generic Infotile
const EpisodeTile = ({ tileStyle, episode, redirectObj }) => {
  // const IndexPage = () => <Redirect to={`/en`} /> // highlight-line
  // console.log(redirectObj)
  // console.log(episode)
  return (
    <>
      <InfoTile
        data={{
          title: episode.title,
          image: episode.image,
          description: episode.description,
          // subtitle:
          // description: stripHtml(episode.description),
          // meta1: format(episode.pub_date_ms),
          meta1: moment(episode.pub_date_ms).format('MMM D YYYY, HH:mm '),
          meta2: pms(episode.audio_length_sec * 1000, {
            colonNotation: true,
          }),
          // meta3={ (episode.job.speakerNames.length + ' speakers'}
        }}
        tileStyle={tileStyle || 'small'}
        onImageClick={() => {
          if (redirectObj) {
            // console.log(redirectObj)
            const { path, queryParams, onImageClickCB } = redirectObj;
            if (onImageClickCB) {
              onImageClickCB(episode);
            } else if (path && queryParams) {
              redirectToPage(path, queryParams);
            }
          }
        }}
        onTitleClick={() => {
          if (redirectObj) {
            // console.log(redirectObj)
            const { path, queryParams, onTitleClickCB } = redirectObj;
            if (onTitleClickCB) {
              onTitleClickCB(episode);
            } else if (path && queryParams) {
              redirectToPage(path, queryParams);
            }
          }
        }}
      />
    </>
  );
};

export default EpisodeTile;
