// REACT AND GATSBY IMPORTS
import React, { useState, useEffect } from 'react';
// CONTAINERS AND COMPONENTS
import Box from 'reusecore/src/elements/Box';
import Container from 'common/src/components/UI/Container';
import EpisodeTile from 'common/src/components/InfoTile/episodeTile';
import Spinner from 'reusecore/src/elements/Spinner'
// STYLING
import JobListWrapper, { Styles } from './jobList.styles'; // CSS styling
import { getJobs } from './handlers'; // Code to handle user I/O
import { pageTimeout } from '../../../../api/BrowserAPI'
// NPM MODULES
import { trackPromise } from 'react-promise-tracker';

// List of all user jobs
export const AllJobs = ({ heading, redirectPath }) => {
  const [jobs, updateJobs] = useState([]);
  const { row, podCol } = Styles;
  let items = [];

  // Performs initial fetch of user's jobs on component load
  useEffect(() => {
    async function fetchData() {
      let jobData = null
      pageTimeout( () => { return jobData == null })
      jobData = await trackPromise ( getJobs() )
      updateJobs(jobData)
    }
    fetchData();
  }, []);

  jobs.map(job =>
    items.push(
      <Box
        {...podCol}
        key={job.jobId}
      >
        <EpisodeTile
          episode={job.episode}
          redirectObj={{
            path: redirectPath,
            queryParams: { jobId: job.jobId },
          }}
        />
      </Box>
    )
  );
  return (
    <JobListWrapper>
      <Container className="user_list_container">

        <Spinner/>
        {/*** SEARCH RESULTS META ***/}
        {items.length > 0 ?
        <Box {...row} alignItems='left' justifyContent='left' flexDirection='column'>
          <h2>{heading}</h2>
          <p>{items.length + ' jobs found'}</p>
        </Box>:<></>}

        {/*** GEWGLE STYLE LOAD RESULTS ***/}
        <Box {...row}>{items}</Box>
      </Container>
    </JobListWrapper>
  );
};