import styled from 'styled-components';

const JobListWrapper = styled.div`
`;

const Styles = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    mt: '10px',
    mb: '10px',
  },
};

export { Styles };
export default JobListWrapper;
